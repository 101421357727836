import React from 'react';
import { images } from '../constants/images';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer>
      <div className="bg-r-img">
        <img src={images?.footerBg} alt="" />
      </div>
      <div className="container">
        <hr className="my-5" />
        <div className="main-footer">
          <div className="row">
            <div className="col-lg-8 col-md-6 foo-list-1">
              <h5 className="tc-primary fs-14 pb-3">
                <strong>ABOUT US</strong>
              </h5>
              <h5 className="tc-primary fs-14" style={{ textAlign: 'justify' }}>
                We build reliable solutions on the basis of Customers business
                needs and objectives. Our customers are always happy!
              </h5>
              <h5 className="tc-primary fs-14" style={{ textAlign: 'justify' }}>
                Plural Code Technologies is a dynamic software development firm, based in New Delhi and serving clients worldwide. Founded in December 2015, our mission is to simplify web and mobile development. Backed by extensive expertise in both web and mobile application engineering, Plural Code Technologies delivers unparalleled professionalism and streamlined business processes. Our portfolio boasts projects of diverse scales, showcasing our adaptability and proficiency in the field.
              </h5>
            </div>
            <div className="col-lg-4 col-md-6 foo-list-2">
              <div className="row mt-3 mt-md-0">
                <div className="col-md-6 py-3 py-md-0">
                  <ul>
                    <li className="pb-1">
                      <Link to={'/about'} className="fs-12 tc-primary">
                        About Us
                      </Link>
                    </li>
                    <li className="pb-1">
                      <Link to={'/services'} className="fs-12 tc-primary">
                        Services
                      </Link>
                    </li>
                    <li className="pb-1">
                      <Link to={'/portfolios'} className="fs-12 tc-primary">
                        Portfolios
                      </Link>
                    </li>
                    <li className="pb-1">
                      <Link to={'/reviews'} className="fs-12 tc-primary">
                        Reviews
                      </Link>
                    </li>
                    <li className="pb-1">
                      <Link to={'/careers'} className="fs-12 tc-primary">
                        Careers
                      </Link>
                    </li>
                    <li className="pb-1">
                      <Link to={'/privacy-policy'} className="fs-12 tc-primary">
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="pb-1">
                      <Link to={'/contact'} className="fs-12 tc-primary">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-center flex-wrap social-links py-4 py-md-0 pb-4">
                    <a
                      href="https://www.instagram.com/pluralcode/?igshid=YmMyMTA2M2Y%3D"
                      target="_blank"
                      className="tc-primary fs-18"
                    >
                      <i className="fa-brands fa-instagram" />
                    </a>
                    <a
                      href="https://www.facebook.com/pluralcode"
                      target="_blank"
                      className="tc-primary fs-18"
                    >
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/plural-code-technologies"
                      target="_blank"
                      className="tc-primary fs-18"
                    >
                      <i className="fa-brands fa-linkedin" />
                    </a>
                  </div>
                  <p className="fs-14 head-font py-md-3 py-2 tc-primary">
                    <strong>Plural Code Technologies</strong>
                    <br />
                    A-1/129, Ground Floor,
                    <br />
                    Sector - 17, Rohini,
                    <br />
                    Delhi 110089, India
                    {/* <br />
                    <a className="tc-primary fs-14" href="tel:+91-9958920231">
                      +91-9958920231
                    </a> */}
                    <br />
                    <a
                      className="tc-primary fs-14"
                      href="mailto:info@pluralcode.com"
                    >
                      info@pluralcode.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 className="tc-primary fs-14" style={{ textAlign: 'center' }}>
        Copyright 2016 - {new Date().getFullYear()} Plural Code Technologies.
        All rights reserved.
      </h5>
    </footer>
  );
}
