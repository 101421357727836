import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import { images } from './constants/images';
import reportWebVitals from './reportWebVitals';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import Portfolios from './pages/Portfolios';
import Contact from './pages/Contact';
import Reviews from './pages/Reviews';
import Technologies from './pages/Technologies';
import About from './pages/About';
import Services from './pages/Services';
import Careers from './pages/Careers';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ScrollToTop from './components/ScrollToTop';

export default function App() {
  return (
    <BrowserRouter>
      <AppHead />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="portfolios" element={<Portfolios />} />
          <Route path="portfolio/:name" element={<Portfolio />} />
          <Route path="contact" element={<Contact />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="technologies" element={<Technologies />} />
          <Route path="services" element={<Services />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="careers" element={<Careers />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const AppHead = () => {
  return (
    <>
      <title>Plural Code Technologies</title>

      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <link rel="shortcut icon" href={images?.logo} type="image/x-icon" />
    </>
  );
};

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
