import { images } from './images';

export const PORTFOLIOS = [
  {
    name: 'avocure',
    image: images?.avocurePortfolio,
    portfolioCard: images?.avocurePortfolioCard,
    title: 'Avocure',
    subTitle: 'Facilitates end to end journey of patients',
    heading:
      'Avocure, facilitates end to end medical travel journey of Patients.We help patients travelling abroad for their treatment or medical travellers, make an informed decision regarding their choice of doctor/hospital.We provide these patients assistance in finding the best doctors / vaidyas and hospitals / wellness centres at a price that fits your budget, and search criteria like preference for a particular region/city, Ayurveda based treatment desired etc. Every Hospital/Wellness Centre’s page will give you an overview of the details of the procedure, a brief introduction to the centre and its staff. You will also be able to get a brief tour of the facility through high resolution images.',
    problem:
      'Avocure team hired us in an ongoing project so that we could take over the development part & they could focus on marketing.',
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'Mysql2',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
    url: 'https://www.avocure.com/',
    forHomePage: true,
    homePageImage: images?.avocureClient,
  },
  {
    name: 'healthsoul',
    image: images?.healthsoulPortfolio,
    portfolioCard: images?.healthsoulPortfolioCard,
    title: 'Healthsoul',
    subTitle: "World's largest directory of healthcare reviews",
    heading:
      'Powered by an advanced practice growth engine, HealthSoul is an all-in-one digital marketing platform focused on healthcare providers and improved patient acquisition through an enhanced online presence. With our 360-degree digital marketing solutions and proven processes, we help providers succeed and improve their patient experience.\n Our mission is to provide effective digital marketing solutions to ensure that you have a consistent flow of patients and your practice stays ahead and stands apart, now and in the future.',
    problem:
      'Our client needed changes to the website for improving seo rankings',
    solution: 'We made necessary changes to the website',
    technologies: ['Ruby on Rails', 'HTML5', 'SEO'],
    url: 'https://www.healthsoul.com/',
    forHomePage: true,
    homePageImage: images?.healthsoulClient,
  },
  {
    name: 'hundred',
    image: images?.hundredPortfolio,
    portfolioCard: images?.hundredPortfolioCard,
    title: 'Hundred',
    subTitle: 'Vitamin & supplements store',
    heading:
      "60% of health issues are connected to our lifestyle behaviors. That's why hundred's health plan is based on the four pillars to wellness: nutrition, sleep, stress balance, and movement - ensuring your vitamin plan is fully integrated with your health goals. They provide personalised vitamins based on requirement.",
    problem:
      'Hundred team hired us on an ongoing project and added us in their development team. They wanted to improve the development speed of the project.',
    solution:
      "We developed the required features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'Spree',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
    url: 'https://www.joinhundred.com/',
    forHomePage: true,
    homePageImage: images?.hundredClient,
  },
  {
    name: 'jetcoin',
    image: images?.jetcoinPortfolio,
    portfolioCard: images?.jetcoinPortfolioCard,
    title: 'Jetcoin',
    subTitle: 'A cryptocurrency platform based in Singapore.',
    heading:
      "Jetcoin is a new digital fuel for the world of sports and entertainment, gives fans and supporters a unique opportunity to benefit directly from the success of their favorite athletes and stars. It disrupts traditional fan-athlete/talent relationships by enabling anyone to launch and support the careers of tomorrow's stars. Jetcoin Institute manages the distribution process of the Jetcoins via Jetcoin Contracts and proofs of social engagement (P.O.S.E.).",
    problem:
      'Jetcoin team hired us to develop a platform distribution process of the digital fuel, jetcoins (JET), via Jetcoin Contracts (JCs) and proof of social engagement (P.O.S.E.).',
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
    url: 'https://www.jetcoin.io/',
    forHomePage: true,
    homePageImage: images?.jetcoinClient,
  },
  {
    name: 'mad_optimist',
    image: images?.madoptimistPortfolio,
    portfolioCard: images?.madoptimistPortfolioCard,
    title: 'Mad Optimist',
    subTitle: 'A studio to build & order custom bathcare solutions.',
    heading:
      'The Mad Optimist is based in Bloomington, Indiana and owned and operated by three best friends and soapsmiths—brothers Mohammed A. and Mohammed M. Mahdi and Anthony Duncan. They have mad respect for Mother Nature, that’s why want to use only all-natural, plant-based ingredients inside every product. There USP is that their bodycare is vegan, cruelty-free, always all-natural, halal, non-GMO, and made from scratch to exact specifications.',
    problem:
      'The Mad Optimist team hired us to develop a platform so that customers can build custom bathcare products of their choice and order them.',
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
    url: 'https://www.themadoptimist.com/',
    forHomePage: true,
    homePageImage: images?.madoptimistClient,
    width: 60,
  },
  {
    name: 'dehub360',
    image: images?.dehub360Portfolio,
    portfolioCard: images?.dehub360PortfolioCard,
    title: 'Dehub 360',
    subTitle: 'Dehub VR School',
    heading:
      'DEHUB Provides visualization solutions for civil and other industries using technologies like Virtual Reality and Augmented Reality.',
    problem:
      'Dehub team hired us to develop a portal to so that students in Kerala can join and learn various courses in the field of VR, AR, MR techs.',
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
    url: 'https://www.linkedin.com/company/dehub-360/about/',
  },
  {
    name: 'stories_album_pro',
    image: images?.storiesalbumproPortfolio,
    portfolioCard: images?.storiesalbumproPortfolioCard,
    title: 'Stories Album Pro',
    subTitle: 'Stories Album Pro allows you to create live photos and albums.',
    heading:
      'Augmented reality platform for photo and arts. The platform where any entrepreneur and artist can create AR in 3 steps, build into a project and increase own profit.',
    problem:
      'Stories Album team hired us to create a feature that can create live photos and album.',
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
    url: 'https://pro.stories-album.com/',
    forHomePage: true,
    homePageImage: images?.storiesalbumproClient,
  },
  {
    name: 'a1_signs',
    image: images?.a1signsPortfolio,
    portfolioCard: images?.a1signsPortfolioCard,
    title: 'A1 Signs',
    subTitle: 'A one-stop-shop for all of your signage needs.',
    problem:
      'A1 Tools & Fixings hired us to create an e-commerce platform for their signage & design tools.',
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
    url: 'https://a1signs-designs.herokuapp.com/',
  },
  {
    name: 'lic',
    image: images?.licPortfolio,
    portfolioCard: images?.licPortfolioCard,
    title: 'LicAllInOne',
    subTitle: 'App for agents of LIC Mauritius',
    heading:
      'The Mauritius Branch of Life Insurance Corporation of India was formed alongside its formation in India in 1956. Five India based private companies which existed in 1956 in Mauritius were amalgamated and thus was formed LIC Branch in Mauritius on 30th Nov 1956.The Total Assets in Mauritius is nearly Rs. 4.4 billion. Customer service has always been the focal point in all its operations. LIC Mauritius has settled claims amounting to Rs. 479.32Mn during 2019-20 which translates to a claim settlement ofaround Rs 2Mn every working day.LIC Mauritius is catering to the life insurance needs of the people of Mauritius by offering a wide range of 35life insurance products, ranging from term insurance, endowment, money back, combo products, single premium products, children policies, joint life insurance, Infinity, etc.',
    problem:
      'LIC Mauritius hired us to create a mobile application tool for their field agents so that they can generate and share policy quotations on the go for customers.',
    solution:
      "We developed the mobile application in Google Flutter as per client's requirements & satisfaction.",
    technologies: ['Android Development', 'iOS Development', 'Flutter'],
    forHomePage: true,
    homePageImage: images?.licClient,
    width: 120,
  },
  {
    name: 'table4u',
    image: images?.table4uPortfolio,
    portfolioCard: images?.table4uPortfolioCard,
    title: 'Table 4U',
    subTitle: 'Book a table at your favorite restaurant in moments!',
    problem:
      'Table 4U team hired us to develop a portal to so that customers can book a table in various resutrants in Poland. Resturant onwners can add their resturants to the portal & mangae the bookings.',
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
    url: 'https://www.table-4-u.com/',
  },
  {
    name: 'josts_se',
    image: images?.jostsPortfolio,
    portfolioCard: images?.jostsPortfolioCard,
    title: 'Josts SE',
    subTitle: 'Josts Engineering Company',
    heading:
      'JOST has worked hard to achieve its position as a global leader in the manufacturing of systems, modules and components for commercial vehicles. The five strong product brands JOST, ROCKINGER, TRIDEC and Quicke form the backbone of our organization. By combining the individual products, efficient concepts for customer-specific applications and future technology systems are created. This is a simple tool for service engineers working in Josts Engineering company to make their life simple and easy at work.',
    problem:
      'Josts hired us to create a simple tool for their service engineers working in Josts Engineering company.They also wanted a web admin panel so that the application administrators can manage their service engineers.',
    solution:
      "We developed those features in ruby on rails & Flutter as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'Flutter',
      'JSON API',
    ],
    url: 'https://play.google.com/store/apps/details?id=com.josts.josts_foreman_app',
    forHomePage: true,
    homePageImage: images?.jostsClient,
  },
  {
    name: 'whoobid',
    image: images?.whoobidPortfolio,
    portfolioCard: images?.whoobidPortfolioCard,
    title: 'Whoobid',
    subTitle: 'Innovative online property auction platform.',
    heading:
      'Whoobid is an innovative online property auction platform revolutionizing the way people buy and sell property. Online agencies are having an ever increasing market share as consumer demands are leading towards online services. Whoobid is promoting openness and transparency within the markets meaning properties are selling for their true market value. Gone are the days of old fashioned inefficient auction room sales which are often inflexible, out of area and poorly advertised. They harness the marketing expertise and experience of local agents but combine this with the innovation of our platform to help agents, sellers and buyers alike. As a company the primary focus is on developing and maintaining great client relationships, working together to give mutual clients the best possible experience.',
    problem:
      'Whoobid team hired us to create an auction platform for property listings in UK.',
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
    url: 'https://www.whoobid.co.uk/',
    forHomePage: true,
    homePageImage: images?.whoobidClient,
    width: 120,
  },
  {
    name: 'whats_app_resellers',
    image: images?.watchPortfolio,
    portfolioCard: images?.watchPortfolioCard,
    title: 'Whats App Resellers',
    subTitle: 'Reselling Platform for Fashion Products',
    problem:
      'Whats App Resellers hired us to create an portal for fashion products where users can place and track their orders.',
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
  },
  {
    name: 'eubep',
    image: images?.eubepPortfolio,
    portfolioCard: images?.eubepPortfolioCard,
    title: 'EUBEP',
    subTitle: 'Multilingual Job Portal in Germany',
    problem:
      'EUBEP hired us to create a multilingual job portal for people looking for job in Germany.',
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
    url: 'https://www.facebook.com/eubep/',
  },
  {
    name: 'bizbook',
    image: images?.bizbookPortfolio,
    portfolioCard: images?.bizbookPortfolioCard,
    title: 'BizBook',
    subTitle: 'An application to manage finance log book',
    problem:
      'Client hired us to create a mobile application where users can manage their credit/debit log book. They also wanted a web admin panel so that the application administrators can manage various users in the application.',
    solution:
      "We developed those features in ruby on rails as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'jQuery',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'Flutter',
      'JSON API',
    ],
  },
  {
    name: 'endless_factory',
    image: images?.endlessfactoryPortfolio,
    portfolioCard: images?.endlessfactoryPortfolioCard,
    title: 'Endless Factory',
    subTitle: 'A multi-seller E-commerce Platform.',
    problem:
      'The Endless Factory team hired us to develop a multi seller E-commerce platform where sellers can list their product for sale.',
    solution:
      "We developed those features in ruby on rails, react native and django as per client's requirements & satisfaction.",
    technologies: [
      'Ruby on Rails',
      'Django',
      'React Native',
      'HTML5',
      'CSS3',
      'PostgreSQL',
      'AJAX',
      'Test Driven Development',
      'JSON API',
    ],
    url: 'https://www.endlessfactory.com/',
  },
  {
    name: 'shopse',
    image: images?.shopsePortfolio,
    portfolioCard: images?.shopsePortfolioCard,
    title: 'Shopse',
    subTitle: 'Cardless EMI, seconds mein done.',
    heading:
      'ShopSe is India’s largest EMI network. With ShopSe, Indian consumers can avail No-Cost EMI benefits on any purchase with wide range of partnered merchants for online and In-store shopping across 100+ Indian cities.',
    problem:
      'The ShopSe team hired us to develop an app for their business that could help Indian consumers avail no-cost EMI benefits on any purchase with wide range of partnered merchants for online and in-store shopping across 100+ Indian Cities.',
    solution:
      "We developed those features in React Js and React native as per client's requirements & satisfaction.",
    technologies: ['React Native', 'React Js'],
    url: 'https://play.google.com/store/apps/details?id=com.shopse.sales',
  },
  {
    name: 'just_KIA',
    image: images?.justkiaPortfolio,
    portfolioCard: images?.justkiaPortfolioCard,
    title: 'Just Kia',
    subTitle: 'KAAM ITNA ASAAN.',
    heading:
      'A tool for business communities & vendors in KIA association in Haryana to connect & do business with each other.',
    problem:
      'The Just KIA team hired us to develop a Mobile App Platform. A tool for business communities and vendors in KUNDLI INDUSTRIAL ASSOCIATION in HARYANA to connect and do efficient business with each other.',
    solution:
      "We developed those features in React Native and Ruby on Rails as per client's requirements & satisfaction.",
    technologies: ['Ruby on Rails', 'React Native'],
    url: 'https://play.google.com/store/apps/details?id=com.kiaapp',
  },
  {
    name: 'timbuktu',
    image: images?.timbuktuPortfolio,
    portfolioCard: images?.timbuktuPortfolioCard,
    title: 'Timbuktu',
    subTitle: 'Earn Real Cash Directly in your Bank Account.',
    heading:
      'Timbuktu is a Made in India platform, which organises online talent competitions across mass entertainment and lifestyle genres. As a user of Timbuktu you can judge a competition, participate in a competition or organise a competition. As a judge you can use your understanding of content and content creators to earn money. As a participant you can use your content creation talent to earn money. As an organiser you can organise competitions for your community to encourage cultural participation. Our mission is to promote art and culture in India and spread it globally. Our vision is to build a creator launchpad which identifies and promotes the most promising talent; powered by the community.',
    problem:
      'The Timbuktu team hired us to develop a Mobile App Platform, that will help the users earn real cash directly in their bank account using the app. They can participate in various competitions as well.',
    solution:
      "We developed those features in React Native and Ruby on Rails as per client's requirements & satisfaction.",
    technologies: ['Ruby on Rails', 'React Native'],
    url: 'https://www.playtimbuktu.com/',
  },
  {
    name: 'owl_print',
    image: images?.owlprintPortfolio,
    portfolioCard: images?.owlprintPortfolioCard,
    title: 'Owl Prints',
    subTitle: 'Create and Sell Products with Print on Demand Drop Shipping.',
    heading:
      'Owl Prints is your Print On Demand,  Drop shipping service provider that aims to uplift brands. They will print, pack and ship your orders to your customers under your brand name, with no minimums!!! From the Point of Purchase to the Point of Sale, our automated services are designed meticulously to ensure a smooth functioning. At Owl Prints, we pride ourselves in our immaculate attention to detail, quality and seamlessness of our operations.',
    problem:
      'The Owl Print team hired us to develop an app for their business that could bridge the gap between the customer and the merchant.',
    solution:
      "We developed those features in ruby on rails, react native and django as per client's requirements & satisfaction.",
    technologies: ['React Native'],
    url: 'https://www.owlprints.in/',
  },
  {
    name: 'pub_live',
    image: images?.publivePortfolio,
    portfolioCard: images?.publivePortfolioCard,
    title: 'PubLive',
    subTitle: 'The Best CMS for Modern Publishers.',
    heading:
      'Publive augments your capabilities to create the most relevant content at the right time. In this ever-changing digital ecosystem, this is already solved by PubLive - poor loading time, unpredictable SEO updates, inefficient content distribution & poor infra.',
    problem:
      'The Pub Live team hired us to develop a web Platform for their business solution that would help the modern publishers with best in class CMS service that will have BETTER CORE WEB VITALS, MORE ORGANIC TRAFFIC and BETTER CONTENT DISTRIBUTION.',
    solution:
      "We developed those features in React Js, Django and Python as per client's requirements & satisfaction.",
    technologies: ['Python', 'React Js', 'Django', 'HTML5', 'CSS3'],
    url: 'https://thepublive.com/',
  },
  {
    name: 'prevalearn',
    image: images?.owlprintPortfolio,
    portfolioCard: images?.owlprintPortfolioCard,
    title: 'Owl Prints',
    subTitle: 'Create and Sell Products with Print on Demand Drop Shipping.',
    heading:
      'Owl Prints is your Print On Demand,  Drop shipping service provider that aims to uplift brands. They will print, pack and ship your orders to your customers under your brand name, with no minimums!!! From the Point of Purchase to the Point of Sale, our automated services are designed meticulously to ensure a smooth functioning. At Owl Prints, we pride ourselves in our immaculate attention to detail, quality and seamlessness of our operations.',
    problem:
      'The Owl Print team hired us to develop an app for their business that could bridge the gap between the customer and the merchant.',
    solution:
      "We developed those features in ruby on rails, react native and django as per client's requirements & satisfaction.",
    technologies: ['React Native'],
    forHomePage: true,
    homePageImage: images?.prevalearnClient,
    isPortfolio: false,
  },
  {
    name: 'calwinkle',
    image: images?.publivePortfolio,
    portfolioCard: images?.publivePortfolioCard,
    title: 'PubLive',
    subTitle: 'The Best CMS for Modern Publishers.',
    heading:
      'Publive augments your capabilities to create the most relevant content at the right time. In this ever-changing digital ecosystem, this is already solved by PubLive - poor loading time, unpredictable SEO updates, inefficient content distribution & poor infra.',
    problem:
      'The Pub Live team hired us to develop a web Platform for their business solution that would help the modern publishers with best in class CMS service that will have BETTER CORE WEB VITALS, MORE ORGANIC TRAFFIC and BETTER CONTENT DISTRIBUTION.',
    solution:
      "We developed those features in React Js, Django and Python as per client's requirements & satisfaction.",
    technologies: ['Python', 'React Js', 'Django', 'HTML5', 'CSS3'],
    forHomePage: true,
    homePageImage: images?.carwinkleClient,
    isPortfolio: false,
  },
];
