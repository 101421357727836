import React from 'react';
import { images } from '../constants/images';
import { PORTFOLIOS } from '../constants/portfolioData';
import ClientCard from '../components/ClientCard';
import '../assets/stylesheets/bootstrap.min.css';
import '../assets/stylesheets/style.css';
import '../assets/stylesheets/responsive.css';

export default function Home() {
  return (
    <div>
      <main className="wrapper">
        <section className="home-hero-sec">
          <img
            src={images?.cSDBanner}
            alt=""
            className="img-fluid d-block d-lg-none"
          />
          <div className="h-text-box">
            <div className="left-vbar">
              <span>
                Plural Code Technologies - A web & mobile development company.
              </span>
            </div>
            <div className="main-text">
              <h1 className="head-font tc-primary">what we offer</h1>
              <hr />
              <ul>
                <li>
                  <a href="#" style={{ textAlign: 'justify' }}>
                    Experience a versatile, yet primarily business-focused
                    design tailored to underpin your web ventures.
                  </a>
                </li>
                <li>
                  <a href="#" style={{ textAlign: 'justify' }}>
                    We harness the full potential of Ruby on Rails and Django as
                    our primary web development frameworks, empowering us to
                    create robust and scalable web applications.
                  </a>
                </li>
                <li>
                  <a href="#" style={{ textAlign: 'justify' }}>
                    Leveraging the capabilities of React Native and Flutter for
                    mobile development, crafting applications for both iOS and
                    Android platforms.
                  </a>
                </li>
                <li>
                  <a href="#" style={{ textAlign: 'justify' }}>
                    These frameworks we use provide the solid infrastructure and
                    flexibility needed to build scalable and feature-rich web
                    applications that cater to the unique needs of businesses
                    across various industries.
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="sec-03 th-bg-secondary-light bg-right">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <img
                  src={images?.aboutUs}
                  className="brr-15 img-fluid h-450"
                  alt=""
                />
              </div>
              <div className="col-lg-6 ps-lg-5">
                <div className="ps-0 px-md-3 pe-0 pt-4 pt-lg-0 ps-lg-5">
                  <p className="tc-primary para-font fs-18 fw-500 mb-2 mt-2">
                    WE REALLY LOVE WHAT WE DO
                  </p>
                  <h2
                    className="tc-primary head-font fs-44"
                    style={{ textAlign: 'justify' }}
                  >
                    while always trying to get the job done!
                  </h2>
                  <div className="pt-2">
                    <hr className="mb-3" />
                  </div>
                  <p
                    className="head-font2 tc-primary fs-18"
                    style={{ textAlign: 'justify' }}
                  >
                    Partner with us to develop we-applications that stand out
                  </p>
                  <ul className="head-font2">
                    <li>
                      <a className="fs-18" style={{ textAlign: 'justify' }}>
                        Plural Code Technologies, based in New Delhi and serving
                        clients globally since December 2015, is a dynamic
                        software development firm dedicated to simplifying web
                        and mobile development.
                      </a>
                    </li>
                    <li>
                      <a className="fs-18" style={{ textAlign: 'justify' }}>
                        With extensive expertise in software engineering, we
                        deliver professional, streamlined processes to our
                        clients.
                      </a>
                    </li>
                    <li>
                      <a className="fs-18" style={{ textAlign: 'justify' }}>
                        Our portfolio showcases projects of varying scales,
                        highlighting our adaptability and proficiency across a
                        wide array of technologies, including Ruby on Rails,
                        Python, Django, React Native, React JS, Flutter, and
                        more.
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-hero-sec">
          <div className="container text-center">
            <h2
              className="tc-primary head-font fs-44 pb-5"
              style={{ textAlign: 'center' }}
            >
              Our core technologies
            </h2>
          </div>
        </section>

        <div
          className="row justify-content-center pb-50"
          style={{
            marginTop: 50,
            margin: '0 auto',
            background: '#333333',
          }}
        >
          <div
            className="col-lg-2 col-md-6"
            style={{ justifyContent: 'center', display: 'flex' }}
          >
            <img
              src={images?.rorTechnology}
              style={{
                height: 200,
                width: 125,
              }}
              className="technology-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-2 col-md-6"
            style={{ justifyContent: 'center', display: 'flex' }}
          >
            <img
              src={images?.pyhton}
              style={{
                height: 200,
                width: 70,
              }}
              className="technology-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-2 col-md-6"
            style={{ justifyContent: 'center', display: 'flex' }}
          >
            <img
              src={images?.django}
              style={{
                height: 200,
                width: 90,
              }}
              className="technology-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-2 col-md-6"
            style={{ justifyContent: 'center', display: 'flex' }}
          >
            <img
              src={images?.mlTechnology}
              style={{
                height: 200,
                width: 90,
              }}
              className="technology-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-2 col-md-6"
            style={{ justifyContent: 'center', display: 'flex' }}
          >
            <img
              src={images?.reactTechnology}
              style={{
                height: 200,
                width: 225,
              }}
              className="technology-fluid"
              alt=""
            />
          </div>

          <div
            className="col-lg-2 col-md-6"
            style={{ justifyContent: 'center', display: 'flex' }}
          >
            <img
              src={images?.reactnativeTechnology}
              style={{
                height: 200,
                width: 225,
              }}
              className="technology-fluid"
              alt=""
            />
          </div>
        </div>

        <div
          className="row justify-content-center pb-50"
          style={{
            marginTop: 50,
            width: '75%',
            margin: '0 auto',
            backgroundImage: `url(../assets/images/ror-logo.png)`,
            borderRadius: 15,
            background: '#0a2458',
          }}
        ></div>

        <section className="page-hero-sec pb-5">
          <div
            className="container text-center"
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h2
              className="tc-primary head-font fs-44"
              style={{ textAlign: 'center' }}
            >
              Why we're the best fit for you
            </h2>
          </div>
        </section>

        <div
          className="row pb-4"
          style={{
            marginTop: 75,
            width: '75%',
            margin: '0 auto',
          }}
        >
          <div className="col-lg-4">
            <div className="d-flex text-center flex-column align-items-center justify-content-center pb-4">
              <p
                className="tc-primary para-font fs-20 fw-500 mb-2 mt-2"
                style={{ textAlign: 'right' }}
              >
                <strong>Premium Solutions</strong>
              </p>
              <p
                className="head-font2 tc-primary fs-18 mb-2 mt-2"
                style={{ textAlign: 'justify' }}
              >
                Our seasoned development team prioritizes quality above all
                else, ensuring top-notch results every time.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex text-center flex-column align-items-center justify-content-center pb-4">
              <p className="tc-primary para-font fs-20 fw-500 mb-2 mt-2">
                <strong>Expert Consultation</strong>
              </p>
              <p
                className="head-font2 tc-primary fs-18 mb-2 mt-2"
                style={{ textAlign: 'justify' }}
              >
                Beyond development, we offer consultancy services to guide you
                in crafting the finest possible app.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex text-center flex-column align-items-center justify-content-center pb-4">
              <p className="tc-primary para-font fs-20 fw-500 mb-2 mt-2">
                <strong>Effortless Communication</strong>
              </p>
              <p
                className="head-font2 tc-primary fs-18 mb-2 mt-2"
                style={{ textAlign: 'justify' }}
              >
                We recognize the need and role of effective communication, and
                we make it a point to provide unwavering support across all time
                zones.
              </p>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{
            width: '75%',
            margin: '0 auto',
            justifyContent: 'center',
          }}
        >
          <div className="col-lg-4">
            <div className="d-flex text-center flex-column align-items-center justify-content-center pb-4">
              <p className="tc-primary para-font fs-20 fw-500 mb-2 mt-2">
                <strong>Adaptability</strong>
              </p>
              <p
                className="head-font2 tc-primary fs-18 mb-2 mt-2"
                style={{ textAlign: 'justify' }}
              >
                Embracing change is what we do the best; we remain flexible
                throughout development while adhering to strict timelines.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex text-center flex-column align-items-center justify-content-center pb-4">
              <p className="tc-primary para-font fs-20 fw-500 mb-2 mt-2">
                <strong>Goal-Oriented Approach</strong>
              </p>
              <p
                className="head-font2 tc-primary fs-18 mb-2 mt-2"
                style={{ textAlign: 'justify' }}
              >
                Your business objectives are our compass; we dedicate ourselves
                to helping you achieve them.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex text-center flex-column align-items-center justify-content-center pb-4">
              <p className="tc-primary para-font head-font fs-20 fw-500 mb-2 mt-2">
                <strong>Transparency</strong>
              </p>
              <p
                className="head-font2 tc-primary fs-18 mb-2 mt-2"
                style={{ textAlign: 'justify' }}
              >
                Trust is what we believe is the fundamental to business success,
                which is why we uphold complete transparency in all our
                dealings.
              </p>
            </div>
          </div>
        </div>

        <section className="sec-03 th-bg-secondary-light bg-left">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-2 order-lg-1 pe-0 pe-lg-5">
                <div className="ps-0 px-md-3 pe-0 pt-4 pt-lg-0 pe-lg-5">
                  <p className="tc-primary para-font fs-18 fw-500 mb-2 mt-2">
                    WHAT MATTERS
                  </p>
                  <h2 className="tc-primary head-font fs-44">Values</h2>
                  <div className="pt-2">
                    <hr className="mb-3" />
                  </div>
                  <p
                    className="head-font2 tc-primary fs-18"
                    style={{ textAlign: 'justify' }}
                  >
                    We have created a set of core values during all those years
                    on the market (and even before). Those define our culture
                    and affect our everyday work, team relations, and
                    cooperation with our clients. We believe in making a
                    commitment to responsibilities, and being accountable for
                    results.
                  </p>
                  <ul className="head-font2">
                    <li>
                      <a className="fs-18">Wide array of framework options</a>
                    </li>
                    <li>
                      <a className="fs-18">
                        Expert consultation through-out the development phase
                      </a>
                    </li>
                    <li>
                      <a className="fs-18">24/7 support and assistance</a>
                    </li>
                    <li>
                      <a className="fs-18">Transparent functioning</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <img
                  src={images?.values}
                  className="brr-15 img-fluid h-450"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section
          className="page-hero-sec"
          style={{
            marginBottom: 75,
          }}
        >
          <div
            className="container text-center"
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h2
              className="tc-primary head-font fs-44"
              style={{ textAlign: 'center' }}
            >
              OUR CLIENTS
            </h2>
            <p className="tc-primary para-font fs-18 fw-500 mb-2 mt-2">
              ALL OF THEM ARE SATISFIED
            </p>
          </div>
        </section>

        <div
          className="row pb-4"
          style={{
            marginTop: 75,
            width: '75%',
            margin: '0 auto',
          }}
        >
          {PORTFOLIOS.slice(0, 8).map((item) => {
            return (
              item?.forHomePage && <ClientCard item={item} key={item.name} />
            );
          })}
        </div>

        <div
          className="row"
          style={{
            width: '75%',
            margin: '0 auto',
            justifyContent: 'center',
          }}
        >
          {PORTFOLIOS.slice(8, 23).map((item) => {
            return (
              item?.forHomePage && <ClientCard item={item} key={item.name} />
            );
          })}
        </div>
      </main>
    </div>
  );
}
