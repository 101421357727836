import React from 'react';
import '../assets/stylesheets/bootstrap.min.css';
import '../assets/stylesheets/style.css';
import '../assets/stylesheets/responsive.css';

export default function Reviews() {
  return (
    <div>
      <main className="wrapper">
        <section className="page-hero-sec">
          <div className="container text-center">
            <h1 className="tc-primary head-font">Reviews</h1>
            <h1 className="tc-primary fs-20">
              What our clients says about us.
            </h1>
          </div>
        </section>
        <hr className="hr-double" />
        <section>
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-3" />
              <div className="col-lg-6">
                <h2
                  className="tc-primary head-font2 sm-caps-none"
                  style={{ textAlign: 'justify' }}
                >
                  We wanted to someone to work with us on our project named IMIT
                  Advisory. Plural Code Technologies did a fabulous work on our
                  project
                </h2>
                <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                  <strong className="tc-primary-light">Bryan Vaz</strong>
                  <span className="dot" />
                  <strong>
                    <strong className="tc-primary-light">Director</strong>
                  </strong>
                  <span className="dot"></span>
                  <strong className="tc-primary-light">IMIT Advisory</strong>
                </p>
                <hr className="my-4" />
                <h2
                  className="tc-primary head-font2 sm-caps-none"
                  style={{ textAlign: 'justify' }}
                >
                  I hired Plural Code Technologies for a website scrapping
                  project, They handled my project very nicely with quick
                  understanding
                </h2>
                <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                  <strong className="tc-primary-light">
                    Michael Zaslavsky
                  </strong>
                </p>
                <hr className="my-4" />
                <h2
                  className="tc-primary head-font2 sm-caps-none"
                  style={{ textAlign: 'justify' }}
                >
                  Hired them to develop a small college project in Python
                  language. They delivered an excellent work with nice
                  communication.
                </h2>
                <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                  <strong className="tc-primary-light">Ankit Jasula</strong>
                </p>
                <hr className="my-4" />
                <h2
                  className="tc-primary head-font2 sm-caps-none"
                  style={{ textAlign: 'justify' }}
                >
                  Highly professional.They have a very good technical knowledge.
                  Delivered the project before the deadline and as per the
                  budget discussed
                </h2>
                <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                  <strong className="tc-primary-light">Zara Miller</strong>
                </p>
                <hr className="my-4" />
                <h2
                  className="tc-primary head-font2 sm-caps-none"
                  style={{ textAlign: 'justify' }}
                >
                  Hired them for writing a small Ruby script. They did an
                  awesome work with excellent speed.
                </h2>
                <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                  <strong className="tc-primary-light">Bobby Singh</strong>
                </p>
                <hr className="my-4" />
                <h2
                  className="tc-primary head-font2 sm-caps-none"
                  style={{ textAlign: 'justify' }}
                >
                  I needed a software for college project. They gave a nice
                  website to me
                </h2>
                <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                  <strong className="tc-primary-light">Ajay Malempati</strong>
                </p>
                <hr className="my-4" />
                <h2
                  className="tc-primary head-font2 sm-caps-none"
                  style={{ textAlign: 'justify' }}
                >
                  I had hired Plural Code Technologie to develop an extension in
                  openproject. They did an extraordinary job
                </h2>
                <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                  <strong className="tc-primary-light">Corrado Tuccitto</strong>
                </p>
                <hr className="my-4" />
                <h2
                  className="tc-primary head-font2 sm-caps-none"
                  style={{ textAlign: 'justify' }}
                >
                  Professional services with good quality code
                </h2>
                <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                  <strong className="tc-primary-light">
                    Shraddha Bandekar
                  </strong>
                </p>
                <hr className="my-4" />
                <h2
                  className="tc-primary head-font2 sm-caps-none"
                  style={{ textAlign: 'justify' }}
                >
                  Plural Code Technologies made it extremely easy to work with
                  them on my website. They gathered the requirements and were
                  able to easily recreate what I was looking for on the website.
                  I would definitely hire them again for future projects
                </h2>
                <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                  <strong className="tc-primary-light">Kashif Abbas</strong>
                  <span className="dot" />
                  <strong>
                    <strong className="tc-primary-light">Owner</strong>
                  </strong>
                  <span className="dot"></span>
                  <strong className="tc-primary-light">
                    New Look Salon & Spa
                  </strong>
                </p>
                <hr className="my-4" />
                <h2
                  className="tc-primary head-font2 sm-caps-none"
                  style={{ textAlign: 'justify' }}
                >
                  Good work fixed an set up the app previously not configured
                  properly. We'll hire again to work on our app
                </h2>
                <p className="d-flex flex-wrap para-font align-items-center pt-3 fs-12">
                  <strong className="tc-primary-light">Eric Alexandre</strong>
                  <span className="dot"></span>
                  <strong>
                    <a href="#" className="tc-primary-light">
                      Founder & CEO
                    </a>
                  </strong>
                  <span className="dot"></span>
                  <strong className="tc-primary-light">
                    Jetcoin Institute
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
