import React from 'react';
import '../assets/stylesheets/bootstrap.min.css';
import '../assets/stylesheets/style.css';
import '../assets/stylesheets/responsive.css';

export default function Careers() {
  return (
    <div>
      <main className="wrapper">
        <section className="page-hero-sec president-sec pt-0 px-5">
          <div className="row justify-content-center">
            <div className="px-4 pt-5">
              <h1 className="tc-primary head-font text-center pb-5">
                Work with us
              </h1>
              <p
                className="tc-primary fs-26 py-3"
                style={{ textAlign: 'justify' }}
              >
                Discover youself at Plural Code Technolgies. Explore
                opportunities across divisions. Work with finest professionals
                from around the world in a sapce that elevate your skills.
              </p>
            </div>
            <div className="px-4 pt-5">
              <h1 className="tc-primary head-font fs-26">
                Explore all open positions.
              </h1>
              <p
                className="tc-primary fs-22 py-3"
                style={{ textAlign: 'justify' }}
              >
                Currently there are no open positions.
              </p>
            </div>
          </div>
          <hr className="hr-double" />
        </section>
      </main>
    </div>
  );
}
