import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PortfolioCard({ item }) {
  const navigate = useNavigate();

  const toPortfolio = () => {
    navigate(`/portfolio/${item?.name}`, { state: { item: item } });
  };

  return (
    <div className="col-lg-3 col-md-6">
      <div className="cards">
        <a onClick={toPortfolio} style={{ cursor: 'pointer' }}>
          <img
            src={item?.portfolioCard}
            className="img-fluid"
            style={{
              height: 280,
              objectFit: 'cover',
            }}
            alt=""
          />
          <h6 className="fs-12 tc-primary py-2 fw-bold text-uppercase">
            {item?.title}
          </h6>
        </a>
      </div>
    </div>
  );
}
