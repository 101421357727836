import React from 'react';
import { images } from '../constants/images';
import '../assets/stylesheets/bootstrap.min.css';
import '../assets/stylesheets/style.css';
import '../assets/stylesheets/responsive.css';

export default function Technologies() {
  return (
    <div>
      <main className="wrapper">
        <section className="page-hero-sec administration-sec">
          <div className="container">
            <div className="container text-center">
              <h1
                className="tc-primary head-font"
                style={{ marginBottom: '75px' }}
              >
                Technologies
              </h1>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img src={images?.ruby} className="technology-fluid" alt="" />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  Ruby
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.railsLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  Ruby on Rails
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.pyhtonLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  Python
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.djangoLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  Django
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.machinelearningLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  Machine Learning
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.reactjsLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  React JS
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.reactnativeLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  React Native
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.flutterLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  Flutter
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.nodejsLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  Node JS
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.jekyllLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  Jekyll
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.mongodbLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  MongoDB
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.mysqlLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  MySQL
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.postgresqlLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  PostgreSQL
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.hamlLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  HAML
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.html5Logo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  HTML5
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.cssLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  CSS3
                </h6>
              </div>

              <div className="col-lg-2 col-md-6">
                <div
                  className="justify-content-center"
                  style={{ display: 'flex' }}
                >
                  <img
                    src={images?.gitLogo}
                    className="technology-fluid"
                    alt=""
                  />
                </div>
                <h6 className="fs-16 tc-primary py-2 fw-bold text-uppercase text-center py-4">
                  GIT
                </h6>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
