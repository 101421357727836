import React, { useState } from 'react';
import { PORTFOLIOS } from '../constants/portfolioData';
import PortfolioCard from '../components/PortfolioCard';
import '../assets/stylesheets/bootstrap.min.css';
import '../assets/stylesheets/style.css';
import '../assets/stylesheets/responsive.css';

export default function Portfolios() {
  const [currentPage, setCurrentPage] = useState(1);

  const pagesData = () => {
    let chunkSize = 8;
    let chunks = [];
    for (let i = 0; i < PORTFOLIOS?.length; i += chunkSize) {
      chunks?.push(PORTFOLIOS?.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const pageData = () => {
    return pagesData()[currentPage - 1];
  };

  return (
    <div>
      <main className="wrapper">
        <section className="page-hero-sec administration-sec">
          <div className="container">
            <div className="container text-center pb-5">
              <h1 className="tc-primary head-font">Portfolio</h1>
              <h1 className="tc-primary fs-20">We take pride in our work.</h1>
              <hr className="hr-double" />
            </div>

            <div className="row justify-content-center">
              {pageData().map((i) => {
                return i?.isPortfolio != false ? (
                  <PortfolioCard item={i} key={i?.name} />
                ) : null;
              })}
            </div>

            <div className="d-flex justify-content-center pt-4">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {pagesData().map((data, index) => {
                    return (
                      <li className="page-item">
                        <a
                          style={{ cursor: 'pointer' }}
                          className="page-link"
                          onClick={() => {
                            setCurrentPage(index + 1);
                          }}
                        >
                          {index + 1}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
