import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ClientCard({ item }) {
  const navigate = useNavigate();

  const toPortfolio = () => {
    if (item?.isPortfolio != false) {
      navigate(`/portfolio/${item?.name}`, { state: { item: item } });
    }
  };

  return (
    <div className="col-lg-2">
      <div className="d-flex text-center flex-column align-items-center justify-content-center pb-4">
        <a
          onClick={toPortfolio}
          style={{
            cursor: item.isPortfolio == false ? 'cursor' : 'pointer',
            borderBottomWidth: 0,
          }}
        >
          <img
            src={item?.homePageImage}
            style={{ width: item?.width ?? 150 }}
            alt=""
          />
        </a>
      </div>
    </div>
  );
}
