import React from 'react';
import { Modal, Box, Slide } from '@mui/material';
import '../assets/stylesheets/bootstrap.min.css';
import '../assets/stylesheets/style.css';
import '../assets/stylesheets/responsive.css';

const style = {
  position: 'absolute',
  top: '0%',
  left: '0%',
  width: '100%',
  height: '100%',
};

export default function ModalComponent({ content, open, setOpen }) {
  const handleClose = () => setOpen(false);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Slide direction="left" in={open}>
        <Box sx={style}>{content}</Box>
      </Slide>
    </Modal>
  );
}
