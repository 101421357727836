import React from 'react';
import '../assets/stylesheets/bootstrap.min.css';
import '../assets/stylesheets/style.css';
import '../assets/stylesheets/responsive.css';

export default function About() {
  return (
    <div>
      <main className="wrapper">
        <section className="page-hero-sec">
          <div className="container">
            <div className="row justify-content-center">
              <div className="row justify-content-center">
                <div className="container text-center pb-5">
                  <h1 className="tc-primary head-font">
                    we really love what we do
                  </h1>
                  <h1 className="tc-primary fs-20">
                    while always trying to get the job done!
                  </h1>
                </div>

                <p
                  className="tc-primary fs-26 py-2"
                  style={{ textAlign: 'justify' }}
                >
                  Plural Code Technologies is a dynamic software development
                  firm, based in New Delhi and serving clients worldwide.
                  Founded in December 2015, our mission is to simplify web and
                  mobile development. Backed by extensive expertise in both web
                  and mobile application engineering, Plural Code Technologies
                  delivers unparalleled professionalism and streamlined business
                  processes. Our portfolio boasts projects of diverse scales,
                  showcasing our adaptability and proficiency in the field.
                  <br />
                  <br />
                  Our proficiency spans a wide array of technologies, including <b>Ruby on Rails</b>, <b>Python</b>, <b>Django</b>, <b>React Native</b>, <b>React JS</b>,
                  <b>Flutter</b>, <b>Machine Learning</b>, <b>Node JS</b>, <b>AWS</b>, <b>Jekyll</b>, <b>MySQL</b>, <b>MongoDB</b>, <b>CSS3</b>, <b>HTML5</b>, <b>HAML</b>, <b>GIT</b>, <b>Twitter Bootstrap</b>, <b>Tailwind</b> and <b>Zurb Foundation.</b>
                  <br />
                  <br />
                  Embracing the Scrum software development and Agile
                  methodologies, we prioritize short development cycles for
                  rapid delivery of a functional software right from the onset
                  of each project. By opting for prototypes over intricate
                  specification documents, we mitigate the risks associated with
                  miscommunication and incomplete requirements, thus ensuring
                  heightened customer satisfaction.
                </p>
                <hr className="hr-double" />
                <h2 className="tc-primary head-font py-3">
                  Delivering Excellence
                </h2>

                <p
                  className="tc-primary fs-26 py-2"
                  style={{ textAlign: 'justify' }}
                >
                  Our stringent quality control measures are integrated into
                  every phase of a project’s execution, guaranteeing top-notch
                  product quality. Our team comprises highly skilled and
                  seasoned professionals unparallelly meeting and going beyond
                  client requirements project after project.
                </p>
                <hr className="hr-double" />
                <h2 className="tc-primary head-font py-3">
                  Efficiency and Peace of Mind:
                </h2>

                <p
                  className="tc-primary fs-26 py-2"
                  style={{ textAlign: 'justify' }}
                >
                  Drawing from our extensive experience in software development
                  and project management, we will not only save you time but
                  also shield you from unnecessary stress, striking an optimal
                  balance between time, quality, and cost.
                </p>
                <hr className="hr-double" />
                <h2 className="tc-primary head-font py-3">
                  Dedicated Quality Team
                </h2>
                <p
                  className="tc-primary fs-26 py-2"
                  style={{ textAlign: 'justify' }}
                >
                  Entrust your mobile and web app projects to our dedicated and
                  proficient team. Enjoy the advantages of cost savings and
                  enhanced team efficiency, all while maintaining control over
                  the workflow according to your preferred schedule and language
                  requirements.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
